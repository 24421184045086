function ready(fn) {
  if (document.readyState !== 'loading') {
    fn();
    return;
  }
  document.addEventListener('DOMContentLoaded', fn);
}

function initCookieBanner() {
  const cookiesBanner = document.querySelector('.cookies-banner');
  const acceptButton = cookiesBanner.querySelector('.buttonscookie');
  // const rejectButton = cookiesBanner.querySelector('.close');

  acceptButton.addEventListener('click', () => {
    localStorage.setItem('cookiesAccepted', 'true');
    cookiesBanner.style.display = 'none';
  });

  // rejectButton.addEventListener('click', () => {
  //   localStorage.setItem('cookiesRejected', 'true');
  //   cookiesBanner.style.display = 'none';
  // });

  if (localStorage.getItem('cookiesAccepted') === 'true' || localStorage.getItem('cookiesRejected') === 'true') {
    cookiesBanner.style.display = 'none';
  } else {
    cookiesBanner.style.display = 'flex';
  }
}

ready(function() {
  initCookieBanner();
});

ready(function() {
  const mobileNav = document.querySelector('.nav ul');

  const burgerIcon = document.querySelector('.burger');

  burgerIcon.addEventListener('click', function () {

    mobileNav.classList.toggle('active');
    burgerIcon.classList.toggle('active');
  });

  mobileNav.addEventListener('click', function () {

    mobileNav.classList.remove('active');
    burgerIcon.classList.remove('active');

  });
});


ready(function() {
  var sliders = document.querySelectorAll('.glide');

  for (var i = 0; i < sliders.length; i++) {
    var glide = new Glide(sliders[i], {
      type: 'carousel',
      startAt: 0,
      perView: 1
    });

    glide.mount();
  }
});

$(document).ready(function(){
  var btn = $('.top');

  $(window).scroll(function () {
    if ($(window).scrollTop() > 400) {
      btn.addClass('show');
    } else {
      btn.removeClass('show');
    }
  });

  btn.on('click', function (e) {
    e.preventDefault();
    $('html, body').animate({ scrollTop: 0 }, '400');
  });
});

ready(function() {
  var acc = document.getElementsByClassName("accordion");
  var i;

  for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function () {
      this.classList.toggle("active");
      var panel = this.nextElementSibling;
      if (panel.style.maxHeight) {
        panel.style.maxHeight = null;
      } else {
        panel.style.maxHeight = panel.scrollHeight + "px";
      }
    });
  }
});
